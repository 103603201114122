import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { faArrowRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NgClass } from '@angular/common';
import { ChatbotInteractionRequest, ConversationStateStore, ConversationStatus } from '@frag-henning/chat';
import { ChatContainerComponent } from '../chat-container/chat-container.component';
import { Subject } from 'rxjs';
import { Subscriptions } from '@frag-henning/utils';

@Component({
  selector: 'chatbot-dashboard',
  standalone: true,
  imports: [FaIconComponent, FormsModule, ChatContainerComponent, ReactiveFormsModule, NgClass],
  templateUrl: './chat-bot-dashboard.component.html',
  styleUrl: './chat-bot-dashboard.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatBotDashboardComponent implements AfterViewInit, OnDestroy {
  @ViewChild('input') input!: ElementRef;
  @ViewChild('inputDiv') inputDiv!: ElementRef;
  inputForm: FormGroup;
  inputValue: AbstractControl;
  rows = 1;
  readonly messageToSend = new Subject<ChatbotInteractionRequest>();
  readonly conversationStore = inject(ConversationStateStore);
  protected readonly faArrowRight = faArrowRight;
  protected readonly ConversationStatus = ConversationStatus;
  protected readonly faSpinner = faSpinner;
  protected readonly inputMaxLength: number = 250;
  private subs: Subscriptions = new Subscriptions();

  constructor(fb: FormBuilder) {
    this.inputForm = fb.group({
      input: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
      hidden: [''],
    });

    this.inputValue = this.inputForm.controls['input'];

    this.subs.put(
      this.inputForm.valueChanges.subscribe(() => {
        this.inputDiv.nativeElement.style.height = 'auto';
        this.inputDiv.nativeElement.style.height = `${this.inputDiv.nativeElement.scrollHeight}px`;
      })
    );

    /*this.subs.put(toObservable(this.conversationStore.currentStatus).pipe(
      filter(status => status === ConversationStatus.Error || status === ConversationStatus.None),
      switchMap(status => iif(() => status === ConversationStatus.Error,
        of(void 0).pipe(
          delay(5000),
          tap(() => this.conversationStore.reset())
        ),
        of(void 0)
      ))
    ).subscribe());*/

    effect(() => {
      const id = this.conversationStore.conversationId();
      if (id !== undefined) {
        localStorage.setItem('conversationId', id);
      } else {
        localStorage.removeItem('conversationId');
      }
    });

    effect(() => {
      if (this.conversationStore.currentStatus() === ConversationStatus.Processing) {
        this.inputForm.get('hidden')?.setErrors({ interactionInProgress: true });
      } else {
        this.inputForm.get('hidden')?.setErrors(null);
      }
      this.inputForm.updateValueAndValidity();
    });

    effect(() => {
      console.log(this.conversationStore.currentStatus());
    });

    this.conversationStore.interactWithChatBot(this.messageToSend);
  }

  ngAfterViewInit(): void {
    this.giveFocus();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribeAll();
  }

  giveFocus(): void {
    this.input.nativeElement.focus();
  }

  submitForm(): void {
    if (this.inputForm.valid) {
      this.messageToSend.next({ messagePayload: this.inputValue.value });
      this.inputForm.reset();
    }
    //TODO else throw screen error
  }

  onEnterKey($event: any) {
    $event.preventDefault();
    this.submitForm();
  }
}
