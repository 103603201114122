import { Route } from '@angular/router';
import { ChatBotDashboardComponent } from './chat/components/chatbot-dashboard/chat-bot-dashboard.component';

export const chatRoutes: Route[] = [
  {
    path: '',
    component: ChatBotDashboardComponent,
    children: [],
  },
];
