<div class="absolute top-0 -right-[8px]">
  <span data-icon="tail-out" class="p0s8B"
    ><svg
      viewBox="0 0 8 13"
      height="13"
      width="8"
      preserveAspectRatio="xMidYMid meet"
      class=""
      version="1.1"
      x="0px"
      y="0px"
      enable-background="new 0 0 8 13">
      <title>tail-out</title>
      <path opacity="0.13" d="M5.188,1H0v11.193l6.467-8.625 C7.526,2.156,6.958,1,5.188,1z"></path>
      <path fill="#cc071e" d="M5.188,0H0v11.193l6.467-8.625C7.526,1.156,6.958,0,5.188,0z"></path></svg
  ></span>
</div>
<div class="rounded-[2rem] p-2 text-white bg-[#cc071e] rounded-tr-none">
  <div class="flex flex-col px-1 md:px-2">
    <markdown class="prose prose-styles prose-usermessage" [data]="message().content" />
  </div>
</div>
