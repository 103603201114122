import { Component, input } from '@angular/core';
import { MarkdownComponent } from 'ngx-markdown';
import { ChatbotMessage } from '../../../services/chat-bot.service';

@Component({
  selector: 'chatbot-message-wrapper',
  standalone: true,
  imports: [MarkdownComponent],
  templateUrl: './chatbot-message-wrapper.component.html',
  styleUrl: './chatbot-message-wrapper.component.css',
})
export class ChatbotMessageWrapperComponent {
  message = input.required<ChatbotMessage>();
}
