import { Component, computed, inject, input } from '@angular/core';
import { MarkdownComponent } from 'ngx-markdown';
import { NgClass } from '@angular/common';
import { ChatbotMessageWrapperComponent } from '../chatbot-message-wrapper/chatbot-message-wrapper.component';
import { UserMessageBoxComponent } from '../user-message-box/user-message-box.component';
import { ConversationStateStore, TreeNode } from '../../../state/conversation.state';
import { ChatbotMessage } from '../../../services/chat-bot.service';
import { MessageOwner } from '../../../services/chat-bot-api.model';

interface CombinedMessageHelper {
  node: TreeNode;
  message: ChatbotMessage;
}

@Component({
  selector: 'chat-message-container',
  standalone: true,
  imports: [
    UserMessageBoxComponent,
    MarkdownComponent,
    NgClass,
    ChatbotMessageWrapperComponent,
    UserMessageBoxComponent,
  ],
  templateUrl: './message-container.component.html',
  styleUrl: './message-container.component.css',
})
export class MessageContainerComponent {
  messages = input.required<TreeNode[] | undefined>();
  readonly conversationStore = inject(ConversationStateStore);

  messageHelpers = computed(() => {
    const messages: CombinedMessageHelper[] = [];
    this.messages()?.forEach(msg => {
      messages.push({ node: msg, message: this.conversationStore.entityMap()[msg.id] });
    });
    return messages;
  });

  protected readonly MessageOwner = MessageOwner;
}
