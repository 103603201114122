export interface HateoasLink {
  href: string;
  method?: HttpMethod;
}

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Patch = 'PATCH',
  Put = 'PUT',
  Delete = 'DELETE',
}

export type HateoasLinkMap = Record<string, HateoasLink>;

export interface HasLinks {
  _links: HateoasLinkMap;
}

export interface GetCollectionResponse<CollectionType> {
  _embedded: CollectionType;
  _links: HateoasLinkMap;
}

export interface AbstractCollectionResponse<CollectionType> {
  _embedded: {
    [key: string]: CollectionType;
  };
  _links: HateoasLinkMap;
}

export class HalParser {
  static parseFirstCollection<T>(response: AbstractCollectionResponse<T>): T[] {
    const embedded = response._embedded;
    const firstKey = Object.keys(embedded)[0];
    return embedded[firstKey] as T[];
  }
}

export function hasKey(linkMap: HateoasLinkMap, key: string): boolean {
  return key in linkMap;
}
