import { ChangeDetectionStrategy, Component, ElementRef, input, output, signal, ViewChild } from '@angular/core';
import { NgClass } from '@angular/common';
import { MarkdownComponent } from 'ngx-markdown';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faArrowRight,
  faCheck,
  faRotateRight,
  faThumbsDown,
  faThumbsUp,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ChatbotMessage, ChatbotMessageRating } from '../../../services/chat-bot.service';
import { MessageOwner } from '../../../services/chat-bot-api.model';
import { Subscriptions } from '@frag-henning/utils';

export interface MessageRateRequest {
  messageId: string;
  rating: ChatbotMessageRating;
  comment: string | undefined;
}

@Component({
  selector: 'chat-user-message-box',
  standalone: true,
  imports: [NgClass, MarkdownComponent, FaIconComponent, ReactiveFormsModule],
  templateUrl: './user-message-box.component.html',
  styleUrl: './user-message-box.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMessageBoxComponent {
  message = input.required<ChatbotMessage>();
  MessageOwner = MessageOwner;
  rateRequest = output<MessageRateRequest>();
  commentInputVisibility = signal<boolean>(false);
  inputValue: AbstractControl;
  inputForm: FormGroup;
  @ViewChild('commentDiv') commentDiv!: ElementRef;
  subs: Subscriptions = new Subscriptions();
  protected readonly faThumbsUp = faThumbsUp;
  protected readonly faThumbsDown = faThumbsDown;
  protected readonly faRotateRight = faRotateRight;
  protected readonly faWarning = faWarning;
  protected readonly ChatbotMessageRating = ChatbotMessageRating;
  protected readonly faArrowRight = faArrowRight;
  protected readonly faCheck = faCheck;

  constructor(fb: FormBuilder) {
    this.inputForm = fb.group({
      comment: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
      hidden: [''],
    });

    this.subs.put(
      this.inputForm.valueChanges.subscribe(() => {
        this.commentDiv.nativeElement.style.height = 'auto';
        this.commentDiv.nativeElement.style.height = `${this.commentDiv.nativeElement.scrollHeight}px`;
      })
    );

    this.inputValue = this.inputForm.controls['comment'];
  }

  triggerRating(value: ChatbotMessageRating, comment: string | undefined): void {
    this.rateRequest.emit({
      messageId: this.message().id,
      rating: value,
      comment:
        comment != undefined ? comment : this.message().comment != undefined ? this.message().comment : undefined,
    });
  }

  setCommentInputVisibility(value: boolean): void {
    this.commentInputVisibility.set(value);
  }

  submitComment(): void {
    if (this.inputForm.valid) {
      this.triggerRating(this.message().rating!, this.inputValue.value);
    }
  }
}
