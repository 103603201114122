import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  output,
  signal,
  Signal,
  ViewChild,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { filter, Subject, switchMap, tap } from 'rxjs';
import { ConversationStateStore, ConversationStatus, TreeNode } from '../../state/conversation.state';
import { toObservable } from '@angular/core/rxjs-interop';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { MessageContainerComponent } from '../message/message-container/message-container.component';
import { MessageRateRequest, UserMessageBoxComponent } from '../message/user-message-box/user-message-box.component';
import { SimpleProcessInfoComponent } from '../message/status/simple-process-info/simple-process-info.component';
import { ToolApprovalComponent } from '../message/status/chat-tool-approval/tool-approval.component';
import { ChatProcessingInfoComponent } from '../message/status/chat-processing-info/chat-processing-info.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscriptions } from '@frag-henning/utils';
import { LoadingIndicatorComponent } from '@frag-henning/ui';

@Component({
  selector: 'chat-container',
  standalone: true,
  imports: [
    UserMessageBoxComponent,
    LoadingIndicatorComponent,
    FaIconComponent,
    MessageContainerComponent,
    ChatProcessingInfoComponent,
    SimpleProcessInfoComponent,
    ToolApprovalComponent,
  ],
  templateUrl: './chat-container.component.html',
  styleUrl: './chat-container.component.css',
  animations: [
    trigger('scaleInOut', [
      state('in', style({ transform: 'scale(1)' })),
      transition(':enter', [style({ transform: 'scale(0)' }), animate('100ms ease-out')]),
      transition(':leave', [animate('100ms ease-in', style({ transform: 'scale(0)' }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatContainerComponent implements OnInit, OnDestroy, AfterViewChecked {
  messageTree: Signal<TreeNode | undefined>;

  messageTreeArray = computed(() => {
    const root = this.messageTree();
    return root ? [root] : undefined;
  });

  @ViewChild('messageContainer') messageContainer: ElementRef | undefined;
  autoScroll = signal<boolean>(true);
  isOnTopOfOverflow = output<boolean>();

  viewCheckedSubject = new Subject();

  readonly store = inject(ConversationStateStore);
  protected readonly ConversationStatus = ConversationStatus;
  protected readonly faArrowDown = faArrowDown;
  private readonly rateMessageSubject = new Subject<MessageRateRequest>();
  private observer!: ResizeObserver;
  private subs: Subscriptions = new Subscriptions();

  constructor(private host: ElementRef) {
    this.messageTree = this.store.tree;

    this.subs.put(
      toObservable(this.messageTree)
        .pipe(
          switchMap(() =>
            this.viewCheckedSubject.pipe(
              filter(() => this.autoScroll()),
              tap(() => this.scrollContainerToBottom())
            )
          )
        )
        .subscribe()
    );

    this.store.rateMessage(this.rateMessageSubject);
  }

  ngOnInit(): void {
    this.observer = new ResizeObserver(() => {
      this.isOverflow();
    });

    this.observer.observe(this.host.nativeElement);
  }

  ngAfterViewChecked(): void {
    this.viewCheckedSubject.next(void 0);
  }

  ngOnDestroy(): void {
    this.observer.unobserve(this.host!.nativeElement);
    this.subs.unsubscribeAll();
  }

  onScroll($event: any): void {
    if (this.messageContainer) {
      const scrollContainer = this.messageContainer.nativeElement;
      const isScrolledToBottom =
        scrollContainer.scrollHeight - scrollContainer.clientHeight <= scrollContainer.scrollTop + 1;

      if (isScrolledToBottom) {
        this.autoScroll.set(true);
      } else {
        this.autoScroll.set(false);
      }

      if (scrollContainer.scrollTop == 0) {
        this.isOnTopOfOverflow.emit(true);
      } else {
        this.isOnTopOfOverflow.emit(false);
      }
    }
  }

  public scrollContainerToBottom(): void {
    this.autoScroll.set(true);
    if (this.messageContainer) {
      this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
    }
  }

  rateMessage(req: MessageRateRequest) {
    this.rateMessageSubject.next(req);
  }

  private isOverflow(): boolean {
    if (this.messageContainer) {
      const scrollContainer = this.messageContainer.nativeElement;
      const isScrolledToBottom =
        scrollContainer.scrollHeight - scrollContainer.clientHeight <= scrollContainer.scrollTop + 1;

      if (!isScrolledToBottom) {
        this.scrollContainerToBottom();
      }
    }
    return true;
  }
}
