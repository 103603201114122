import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { concatMap, iif, of, pipe, switchMap, take, tap, throwError } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { computed, inject } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { addEntity, removeAllEntities, setAllEntities, updateEntity, withEntities } from '@ngrx/signals/entities';
import { ChatbotMessage, ChatBotService } from '../services/chat-bot.service';
import { ChatbotConversationCreatedEvent, ChatbotMessageDeltaEvent } from '../services/chat-bot-api.model';
import { MessageRateRequest } from '../components/message/user-message-box/user-message-box.component';
import { CONVERSATION_BEHAVIOR_CONFIG } from '@frag-henning/injection-context';

export interface ChatbotInteractionRequest {
  messagePayload: string;
}

export enum ConversationStatus {
  None = 'None',
  Init = 'Init',
  Idle = 'Idle',
  Error = 'Error',
  Processing = 'Processing',
}

export interface ConversationState {
  conversationId: string | undefined;
  currentStatus: ConversationStatus;
}

export interface TreeNode {
  id: string;
  children: TreeNode[];
}

export const ConversationStateStore = signalStore(
  { providedIn: 'root' },
  withState(() => {
    const config = inject(CONVERSATION_BEHAVIOR_CONFIG);
    return {
      conversationId: config.persistConversation ? localStorage.getItem('conversationId')?.toString() : undefined,
      currentStatus: ConversationStatus.Init,
    } as ConversationState;
  }),
  withEntities<ChatbotMessage>(),
  withComputed(store => ({
    lastMessageIdInChat: computed(() => {
      const messages = store.entities();
      return messages.find(msg => msg.children == undefined || msg.children.length == 0);
    }),
    tree: computed(() => {
      const messages = store.entities();
      const nodeMap: { [key: string]: TreeNode } = {};

      let root: TreeNode | null = null;
      messages.forEach(message => {
        nodeMap[message.id] = { id: message.id, children: [] };
      });
      messages.forEach(message => {
        if (message.parent === undefined) {
          root = nodeMap[message.id];
        } else if (message.parent in nodeMap) {
          nodeMap[message.parent].children.push(nodeMap[message.id]);
        }
      });

      if (!root) {
        if (messages.length > 0) {
          throw new Error('Chat has no root');
        }
        return undefined;
      }
      return root;
    }),
    orderedByCreationDateMessages: computed(() => {
      const sortedMessages = store.entities();
      sortedMessages.sort((a, b) => a.whenCreated.toMillis() - b.whenCreated.toMillis());
      return sortedMessages;
    }),
  })),
  withMethods((store, chatBotService = inject(ChatBotService)) => ({
    reset(): void {
      patchState(store, { currentStatus: ConversationStatus.None, conversationId: undefined }, removeAllEntities());
    },
    getConversationMessages: rxMethod<string | undefined>(
      pipe(
        take(1),
        switchMap(conversationId => {
          if (conversationId === undefined) {
            return of(void 0).pipe(tap(() => patchState(store, { currentStatus: ConversationStatus.None })));
          } else {
            return chatBotService.getAllMessagesFromConversation(conversationId).pipe(
              tapResponse({
                next: messages => {
                  patchState(store, { currentStatus: ConversationStatus.Idle }, setAllEntities(messages));
                },
                error: () => {
                  console.warn(
                    `Could not load messages from conversation ${conversationId}. Proceeding with new conversation`
                  );
                  patchState(
                    store,
                    { currentStatus: ConversationStatus.None, conversationId: undefined },
                    removeAllEntities()
                  );
                },
              })
            );
          }
        })
      )
    ),
    interactWithChatBot: rxMethod<ChatbotInteractionRequest>(
      pipe(
        tap(() => patchState(store, { currentStatus: ConversationStatus.Processing })),
        switchMap(request =>
          iif(
            () => store.conversationId() === undefined,
            chatBotService.startConversation(request.messagePayload),
            of(store.lastMessageIdInChat()).pipe(
              take(1),
              switchMap(lastMessage =>
                iif(
                  () => lastMessage !== undefined,
                  chatBotService.exchangeMessage(store.conversationId()!, lastMessage!.id, request.messagePayload),
                  throwError(() => new Error('last message is null'))
                )
              )
            )
          ).pipe(
            tapResponse({
              next: rawEvent => {
                if (rawEvent.type === 'error') {
                  //const error = rawEvent as ErrorEvent;
                  //TODO
                }
                const event = rawEvent as MessageEvent;
                switch (event.type) {
                  case 'conversation.created': {
                    const conversationCreatedBody = <ChatbotConversationCreatedEvent>JSON.parse(event.data);
                    patchState(store, { conversationId: conversationCreatedBody.id });
                    break;
                  }
                  case 'conversation.message.created': {
                    const messageCreatedEvent = <ChatbotMessage>JSON.parse(event.data);

                    messageCreatedEvent.parent = store.lastMessageIdInChat()?.id;
                    const parent = store.lastMessageIdInChat();
                    if (parent != undefined) {
                      if (parent.children != undefined) {
                        parent.children.push(messageCreatedEvent.id);
                      } else {
                        parent.children = [messageCreatedEvent.id];
                      }
                      patchState(store, addEntity(messageCreatedEvent), addEntity(parent));
                    } else {
                      patchState(store, addEntity(messageCreatedEvent));
                    }
                    break;
                  }
                  case 'conversation.message.delta': {
                    const messageDeltaBody = <ChatbotMessageDeltaEvent>JSON.parse(event.data);
                    const storedEntity = store.entityMap()[messageDeltaBody.id];
                    if (storedEntity != null) {
                      if (storedEntity.content == undefined) {
                        patchState(
                          store,
                          updateEntity({
                            id: messageDeltaBody.id,
                            changes: { content: messageDeltaBody.delta },
                          })
                        );
                      } else {
                        patchState(
                          store,
                          updateEntity({
                            id: messageDeltaBody.id,
                            changes: { content: storedEntity.content + messageDeltaBody.delta },
                          })
                        );
                      }
                    } else {
                      console.warn('Message delta received but message does not exist. Ignoring it...');
                    }
                    break;
                  }
                  case 'conversation.message.completed': {
                    const newMessage = <ChatbotMessage>JSON.parse(event.data);
                    const storedEntity = store.entityMap()[newMessage.id];
                    if (storedEntity != null) {
                      console.warn(
                        'Message ' + newMessage.id + ' was already in conversation state. Overriding it now...'
                      );
                    }
                    //patchState(store, addEntity(newMessage));
                    break;
                  }
                  case 'done':
                    patchState(store, { currentStatus: ConversationStatus.Idle });
                    break;
                }
              },
              error: error => {
                console.error(error);
              },
            })
          )
        )
      )
    ),
    rateMessage: rxMethod<MessageRateRequest>(
      pipe(
        concatMap(request =>
          chatBotService.rateMessage(store.conversationId(), request.messageId, request.rating, request.comment).pipe(
            tapResponse({
              next: () => {
                patchState(
                  store,
                  updateEntity({
                    id: request.messageId,
                    changes: { rating: request.rating, comment: request.comment },
                  })
                );
              },
              error: () => {
                console.error('Error while doing rating http request');
              },
            })
          )
        )
      )
    ),
  })),
  withHooks({
    onInit({ getConversationMessages, conversationId }) {
      getConversationMessages(conversationId);
    },
  })
);
