import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { ChatbotConversationEmbeddedMessageCollection } from './chat-bot-api.model';
import { ChatbotMessageRating } from './chat-bot.service';
import { GetCollectionResponse, SSE_Client } from '@frag-henning/utils';
import { CHAT_API_CONFIG, ChatBotApiConfigurationProperties } from '@frag-henning/injection-context';

@Injectable({
  providedIn: 'root',
})
export class ChatBotApi {
  private readonly baseUrl: string;

  constructor(
    private streamClient: SSE_Client,
    private httpClient: HttpClient,
    @Inject(CHAT_API_CONFIG) chatApiConfig: ChatBotApiConfigurationProperties
  ) {
    this.baseUrl = chatApiConfig.apiBaseUrl;
  }

  public getConversationMessages(
    conversationId: string
  ): Observable<GetCollectionResponse<ChatbotConversationEmbeddedMessageCollection>> {
    return this.httpClient.get<GetCollectionResponse<ChatbotConversationEmbeddedMessageCollection>>(
      `${this.baseUrl}/conversations/${conversationId}/messages`
    );
  }

  public exchangeMessage(conversationId: string, lastMessageId: string, content: string): Observable<Event> {
    return this.streamClient.stream(
      `${this.baseUrl}/conversations/${conversationId}/messages`,
      false,
      { conversationId: conversationId, lastMessage: lastMessageId, content: content },
      'POST'
    );
  }

  public initConversation(firstMessagePayload: string): Observable<Event> {
    return this.streamClient.stream(`${this.baseUrl}/conversations`, false, { content: firstMessagePayload }, 'POST');
  }

  public rateMessage(
    conversationId: string,
    messageId: string,
    rating: ChatbotMessageRating,
    comment: string | undefined
  ): Observable<boolean> {
    return this.httpClient
      .post(
        `${this.baseUrl}/conversations/${conversationId}/messages/${messageId}/ratings`,
        { conversationId, messageId, rating, comment },
        { observe: 'response' }
      )
      .pipe(map(response => response.status == 201));
  }
}
