<div class="h-full w-full flex flex-col">
  <!--@if (appStateStore.apiHealthy() != false) {-->
  <chat-container class="grow flex min-h-0 w-full relative" />
  <!-- explicit relative positioning for scroll down icon in component -->
  <div class="w-full flex mt-2 px-2 sm:px-10 max-w-[1200px] place-self-center">
    <form class="w-full" [formGroup]="inputForm" (ngSubmit)="this.submitForm()">
      <div class="mb-2">
        <div class="relative rounded-2xl bg-white drop-shadow-lg flex pl-4 sm:px-7 py-2 sm:py-4">
          <textarea
            #inputDiv
            maxlength="{{ inputMaxLength }}"
            type="text"
            class="w-full resize-none border-0 bg-transparent custom-outline placeholder-black/50 overflow-y-auto pr-6"
            placeholder="Stelle deine Frage..."
            [rows]="rows"
            (keydown.enter)="onEnterKey($event)"
            [formControl]="$any(inputValue)"
            #input>
          </textarea>
          @if (this.conversationStore.currentStatus() === ConversationStatus.Processing) {
            <div class="absolute right-1.5 sm:right-2 bottom-2 sm:bottom-2">
              <fa-icon
                [icon]="faSpinner"
                size="xl"
                animation="spin"
                class="hidden sm:block py-2 px-2.5 rounded-xl text-white bg-[#cc071e]" />
              <fa-icon
                [icon]="faSpinner"
                size="xl"
                animation="spin"
                class="sm:hidden py-1.5 px-2 rounded-xl text-white bg-[#cc071e]" />
            </div>
          } @else {
            <button
              type="submit"
              [disabled]="!this.inputForm.valid"
              class="absolute right-1.5 sm:right-2 bottom-2 sm:bottom-2">
              <fa-icon
                [icon]="faArrowRight"
                size="xl"
                [ngClass]="this.inputForm.valid ? 'bg-[#cc071e] hover:bg-[#990617]' : 'bg-gray-400'"
                class="hidden sm:block py-2 px-2.5 rounded-xl text-white transition-colors duration-200"></fa-icon>
              <fa-icon
                [icon]="faArrowRight"
                [ngClass]="this.inputForm.valid ? 'bg-[#cc071e] hover:bg-[#990617]' : 'bg-gray-400'"
                class="sm:hidden py-1.5 px-2 rounded-xl text-white transition-colors duration-200"></fa-icon>
            </button>
          }
        </div>
        <div class="flex place-content-between text-xs text-gray-400 pt-1">
          <div class="px-1 sm:pr-4 sm:pl-7">
            Es handelt sich um generativen Inhalt. Erwäge, wichtige Information erneut zu prüfen.
          </div>
          <div class="px-4">{{ inputDiv.value.length }}/{{ inputMaxLength }}</div>
        </div>
      </div>
    </form>
  </div>
  <!--} @else {
  <div class="w-full h-full flex flex-col place-items-center justify-center p-24 text-center">
    <div class="font-semibold text-2xl">Störung</div>
    <div>
      Dieser Dienst funktioniert derzeit nicht. Bitte warte einen Moment und kontaktiere bei Bedarf die
      Systemadministration der Fachschaft, um das Problem zu lösen.
    </div>
  </div>
  }-->
</div>
