import { Injectable } from '@angular/core';
import { from, map, Observable, switchMap, throwError, toArray } from 'rxjs';
import { DateTime } from 'luxon';
import { MessageOwner } from './chat-bot-api.model';
import { ChatBotApi } from './chat-bot-api.service';

export enum ChatbotMessageRating {
  Favorable = 'favorable',
  Unfavorable = 'unfavorable',
  Problematic = 'problematic',
}

export interface ChatbotMessage {
  id: string;
  content: string | undefined;
  owner: MessageOwner;
  whenCreated: DateTime;
  parent: string | undefined;
  children: string[] | undefined;

  rating: ChatbotMessageRating | undefined;
  comment: string | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class ChatBotService {
  constructor(private api: ChatBotApi) {}

  public startConversation(firstMessageContent: string): Observable<Event> {
    return this.api.initConversation(firstMessageContent);
  }

  public exchangeMessage(conversationId: string, lastMessageId: string, content: string): Observable<Event> {
    return this.api.exchangeMessage(conversationId, lastMessageId, content);
  }

  public getAllMessagesFromConversation(conversationId: string): Observable<ChatbotMessage[]> {
    return this.api.getConversationMessages(conversationId).pipe(
      switchMap(response =>
        from(response._embedded.messages).pipe(
          map(message => {
            return {
              ...message,
              whenCreated: DateTime.fromISO(message.creationDate),
              rating: undefined,
              comment: undefined,
            };
          }),
          toArray()
        )
      )
    );
  }

  public rateMessage(
    conversationId: string | undefined,
    messageId: string,
    rating: ChatbotMessageRating,
    comment: string | undefined
  ): Observable<boolean> {
    if (conversationId != undefined) {
      return this.api.rateMessage(conversationId, messageId, rating, comment);
    }
    return throwError(() => 'Conversation Id is not set while trying to review message');
  }
}
