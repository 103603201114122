<div #messageContainer class="overflow-y-auto w-full">
  <div class="h-full flex place-content-center px-2 sm:px-10">
    <div class="px-2 sm:px-10 flex-grow flex flex-col min-h-0 max-w-[1200px]" (wheel)="onScroll($event)">
      @if (messageTree() !== undefined) {
        <div class="flex-grow"></div>
        <chat-message-container [messages]="messageTreeArray()" />
      }
      @if (store.currentStatus() !== ConversationStatus.Error) {
        <!--<simple-process-info text="Tue wichtige Dinge..."/>-->
        <!--<tool-approval text='"Frag Henning!" möchte in deinem Namen auf [**"online.rwth-aachen.de"**](https://online.rwth-aachen.de) zugreifen. Hierfür ist deine Zustimmung erforderlich. Ist dies erlaubt?'/>-->
        @if (!autoScroll()) {
          <button
            @scaleInOut
            class="absolute bottom-10 sm:bottom-6 right-1/2 rounded-full h-8 w-8 bg-black opacity-60"
            (click)="scrollContainerToBottom()">
            <fa-icon [icon]="faArrowDown" class="text-slate-200" />
          </button>
        }
      } @else if (store.currentStatus() === ConversationStatus.None) {
        <div class="h-full flex place-items-center place-content-center">
          <div class="flex flex-col self-center">
            <img class="rounded-full h-32 w-32 mx-auto" src="/assets/henning.png" />
            <!--<div class="text-center">Stelle Henning deine Fragen!</div>-->
          </div>
        </div>
      } @else if (store.currentStatus() === ConversationStatus.Error) {
        <div class="h-full max-w-2xl mx-auto flex place-items-center place-content-center">
          <div class="flex flex-col">
            <div class="font-semibold text-lg text-center">Oooopppsss...</div>
            <div class="text-center">
              Da ist scheinbar gerade etwas schiefgelaufen! Die Unterhaltung wird in Kürze zurückgesetzt. Wir würden uns
              über darüber freuen, wenn du uns den Fehler meldest!
            </div>
          </div>
        </div>
      } @else {
        <loading-indicator class="h-full" />
      }
    </div>
  </div>
</div>
