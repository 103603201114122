@if (messageHelpers()[0]; as message) {
  <div class="flex flex-col">
    <div class="relative my-7 flex" [class.flex-row-reverse]="message.message.owner === MessageOwner.User">
      <div class="max-w-[70vw]">
        @if (message.message.owner === MessageOwner.Chatbot) {
          <chatbot-message-wrapper [message]="message.message" />
        } @else if (message.message.owner === MessageOwner.User) {
          <chat-user-message-box class="place-self-end" [message]="message.message" />
        }
      </div>
    </div>
    <chat-message-container [messages]="message.node.children" />
  </div>
}
