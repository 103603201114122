import { Injectable } from '@angular/core';
import { SseClient } from 'ngx-sse-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SSE_Client {
  constructor(private client: SseClient) {}

  public stream<TBody>(url: string, keepAlive: boolean, body: TBody, method: string): Observable<Event> {
    return this.client.stream(url, { keepAlive: keepAlive }, { body: body }, method);
  }
}
