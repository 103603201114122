import { InjectionToken } from '@angular/core';

export const AUTH_CONFIG = new InjectionToken<AuthConfigurationProperties>('auth.config');

export interface AuthConfigurationProperties {
  issuer: string;
  redirectUri: string;
  clientId: string;
  scopes: string[];
  verboseLogging: boolean;
}
