import { HasLinks } from '@frag-henning/utils';

export enum MessageOwner {
  User = 'user',
  Chatbot = 'chatbot',
}

export interface ChatbotConversationCreatedEvent {
  id: string;
}

export interface ChatbotMessageCreatedEvent {
  id: string;
  content: string;
}

export interface ChatbotMessageCompletedEvent {
  id: string;
  content: string;
}

export interface ChatbotMessageDeltaEvent {
  id: string;
  delta: string;
}

export interface ChatbotConversationApiMessage extends HasLinks {
  id: string;
  content: string | undefined;
  owner: MessageOwner;
  creationDate: string;

  rating: undefined;
  comment: string | undefined;

  parent: string | undefined;
  children: string[] | undefined;
}

export interface ChatbotConversationEmbeddedMessageCollection {
  messages: ChatbotConversationApiMessage[];
}
