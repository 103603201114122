import { Subscription } from 'rxjs';
import * as uuid from 'uuid';

export class Subscriptions {
  private all: Map<string, Subscription> = new Map();

  get count(): number {
    return this.all.size;
  }

  put(subscription?: Subscription): string | undefined {
    if (!subscription) {
      return;
    }
    const uid = uuid.v4();
    this.all.set(uid, subscription);
    return uid;
  }

  unsubscribe(uid?: string): void {
    if (!uid) {
      return;
    }
    const subscriber = this.all.get(uid);
    subscriber?.unsubscribe();
    this.all.delete(uid);
  }

  unsubscribeAll(): void {
    this.all.forEach(subscription => subscription.unsubscribe());
    this.all.clear();
  }

  isClosed(): boolean {
    if (!this.all?.size) {
      return true;
    }
    return !Array.from(this.all.values()).find(sub => !sub.closed);
  }
}
